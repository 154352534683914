// Circle
// @author NFC Egypt <https://nfc.com.eg>
@mixin circle($dimensions) {
  width: $dimensions;
  height: $dimensions;
  border-radius: 50%;
}
// Ellipsis
// @author NFC Egypt <https://nfc.com.eg>
@mixin ellipsis($w) {
  max-width: $w;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
}
// Overlay
// @author NFC Egypt <https://nfc.com.eg>
@mixin overlay($bg-color, $alpha) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($bg-color, $alpha);
}
