@use "../abstracts" as *;

.share {
  &__box {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.226);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &-wrapper {
      width: 90%;
      max-width: rem(375);
      background-color: var(--colors-white);
      border: 1px solid var(--colors-gray-light);
      border-radius: rem(5);
      box-shadow: 0 6px 16px hsl(0deg 0% 0% / 0.3);
      transform: translateY(-50px);
      transition: 0.3s;
      @include screen(md) {
        max-width: rem(456);
      }
      @include screen(lg) {
        max-width: rem(528);
      }
    }
    &.open {
      visibility: visible;
      opacity: 1;
      & > div {
        transform: translateY(0);
      }
    }
    &-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(16);
      border-bottom: 1px solid var(--colors-gray-light);
      h2 {
        font-size: rem(24);
        font-weight: var(--fw-medium);
      }
    }
    &-close-btn {
      width: rem(36);
      height: rem(36);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:hover {
        background-color: rgba(128, 128, 128, 0.114);
      }
      svg {
        position: relative;
        z-index: -1;
        width: rem(32) !important;
        height: rem(32) !important;
      }
    }
    &-content {
      padding: rem(16);
      display: flex;
      flex-direction: column;
      row-gap: rem(16);
      .field {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        label {
          width: 100%;
        }
        input {
          width: 72%;
        }
        button {
          width: 25%;
          margin-left: auto;
          &[id="url"],
          &[id="vcf"] {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.068);
          }
        }
        input,
        button {
          height: rem(40);
          @include screen(md) {
            height: rem(48);
          }
        }
      }
    }
  }
}
