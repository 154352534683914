@use "../abstracts" as *;

.nfc {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.464);
  opacity: 0;
  &__scan-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: auto;
    padding: 0 rem(24) rem(24);
    background-color: var(--colors-white);
    border-radius: rem(5);
  }
  &__phone {
    width: 90%;
    svg {
      width: 100%;
    }
  }
  &__heading {
    max-width: rem(235);
    font-weight: var(--fw-medium);
    text-align: center;
    margin-bottom: rem(56);
  }
  &__radio-box {
    align-self: flex-start;
    &-item {
      font-size: rem(14);
      display: flex;
      align-items: center;
      &:first-of-type {
        margin-bottom: rem(12);
      }
      span[data-checked] {
        display: inline-block;
        width: rem(24);
        height: rem(24);
        margin-right: rem(12);
        border: 1px solid var(--colors-gray);
        border-radius: rem(5);
      }
      span[data-checked="true"] {
        border-color: var(--colors-red);
        background-color: var(--colors-red);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/check-icon.svg);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
  &__button {
    width: 100%;
    &:first-child {
      margin-top: rem(32);
      margin-bottom: rem(6);
    }
    &-cancel {
      width: fit-content;
      margin: auto;
      color: var(--colors-red);
    }
  }

  // Ready to Scan
  &__rts {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.213);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      padding: rem(32) rem(24) rem(24);
      margin: 0 rem(20) rem(48);
      background-color: var(--colors-white);
      border-radius: rem(5);
      opacity: 0;
      transform: translateY(200px);
      transition: 0.5s;
    }
    &.open {
      opacity: 1;
      visibility: visible;
      & > * {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-heading {
      font-size: rem(22);
      font-weight: var(--fw-bold);
    }
    &-icon {
      @include circle(rem(125));
      border: 3px solid var(--colors-blue);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-block: rem(28) rem(16);
      svg {
        width: 50%;
        height: 50%;
        path {
          fill: var(--colors-blue);
        }
      }
      //
      .signal {
        &__one {
          animation: signal2 1.5s infinite;
        }
        &__two {
          animation: signal3 1.5s infinite;
        }
      }
      &.active {
        border-color: var(--colors-green);
        svg {
          width: 90%;
          height: 90%;
          path {
            fill: var(--colors-green);
          }
        }
      }
      &.error {
        border-color: #ffc108;
        svg {
          width: rem(100);
          height: rem(100);
          path {
            fill: #ffc108;
          }
        }
      }
    }
    &-button {
      width: 100%;
      margin-top: rem(28);
    }
  }
}

@keyframes signal {
}
@keyframes signal2 {
  0%,
  100% {
    opacity: 100%;
  }
  17% {
    opacity: 0;
  }
}
@keyframes signal3 {
  0%,
  100% {
    opacity: 100%;
  }
  33% {
    opacity: 0;
  }
}

// NFC Error
.nfc__error {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding-inline: rem(32);
  background-color: rgba(0, 0, 0, 0.165);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  &-wrapper {
    min-width: 200px;
    max-width: 320px;
    padding: rem(16);
    background-color: var(--colors-white);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12);
    border-radius: rem(5);
    transform: translateY(-20px);
  }
  &.open {
    opacity: 1;
    visibility: visible;
  }
  &-msg-wrapper {
    display: flex;
    margin-bottom: rem(16);
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    @include circle(rem(24));
    margin-right: rem(8);
    background-color: #ffc108;
    svg {
      width: rem(24);
      height: rem(24);
      path {
        fill: var(--colors-white);
      }
    }
  }
  &-button {
    width: 100%;
  }
}
