@use "../abstracts" as *;

.statistics {
  .container {
    padding-block: rem(24);
    @include screen(lg) {
      padding-block: rem(40);
    }
  }
  h1 {
    padding-bottom: rem(20);
  }
  &__contacts {
    margin-top: rem(16);
    padding: rem(16) 0;
    height: rem(360);
    background-color: #f3f3f3;
    border-radius: rem(5);
  }
  ul {
    height: 100%;
    overflow: auto;
  }
  li:not(li:last-of-type) {
  }
.dashboard{
.btn{
  width: fit-content !important;
}  
}
  .item {
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 5px;
    &:hover {
      background: #ddd;
    }
    .name {
      flex: 1;
      margin-left: 8px;
    }
    .action {
      flex: 0 0;
      a,
      button {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        &:hover {
          background-color: #fff;
        }
        svg {
          width: 18px;
          height: 24px;
          margin-top: 5px;
        }
      }
    }
  }
}
