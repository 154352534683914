@use "../abstracts" as *;

.edit-card {
  .container {
    padding-bottom: rem(40);
  }
  .react-colorful {
    width: 100%;
    height: 120px;
  }
  .profile-customization-box {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    @include screen(lg) {
      padding-left: 15em;
    }
    .item {
      flex: 1;
      min-width: 300px;
    }
    .fontface {
      flex: 0 0 100%;
      z-index: 999;
      #font-picker {
        width: 100%;
      }
    }
    .demo {
      padding: 25px;
      * {
        color: inherit;
      }
    }
  }
}
