@use "../abstracts" as *;
/* Container */
.container {
  width: 100%;
  padding-inline: em(16);
  margin-inline: auto;

  @include screen(sm) {
    padding-inline: em(40);
  }
  @include screen(md) {
    width: em(689);
    padding-inline: 0;
  }
  @include screen(lg) {
    width: em(892);
  }
}
