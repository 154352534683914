@use "../abstracts" as *;

.footer {
  display: flex;
  align-items: center;
    height: rem(60);
    @include screen(md) {
    height: rem(80);
  }
  small {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(16);
    @include screen(lg) {
      margin-bottom: 0;
    }
  }
  &__logo {
    width: rem(55);
    height: rem(14);
    margin-left: rem(6);
    line-height: 0;
    @include screen(lg) {
      width: rem(80);
      height: rem(17);
    }
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
