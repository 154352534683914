@use "../abstracts" as *;

.card-profile {
  min-height: calc(100vh - 145px);
  .container {
    padding-bottom: rem(56);
  }
  &__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 25px;
  }
  &__heading {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include screen(lg) {
      width: 100%;
      margin-left: 0;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-name {
      max-width: rem(300);
      font-size: rem(20);
      line-height: line-hg(26, 20);
      margin-block: rem(24) rem(6);
      text-transform: capitalize;
      @include screen(md) {
        max-width: rem(320);
        font-size: rem(28);
        line-height: line-hg(36, 28);
        margin-block: rem(48) rem(10);
      }
      @include screen(lg) {
        margin-top: 0;
      }
    }
    &-occupation {
      font-size: rem(13);
      line-height: line-hg(16, 12);
      text-transform: capitalize;
      @include screen(md) {
        font-size: rem(16);
        line-height: line-hg(26, 16);
      }
    }
  }
  &__title {
    margin-bottom: rem(3);
  }
  &__btn {
    align-self: flex-end;
    display: flex;
    gap: rem(8);
    margin: rem(24) auto 0;
    @include screen(md) {
      margin-top: rem(28);
    }
    @include screen(lg) {
      margin-top: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
    [role="button"],
    .icon {
      width: rem(48);
      height: rem(48);
      @include screen(lg) {
        width: rem(40);
        height: rem(40);
      }
    }
    [role="button"] {
      width: fit-content !important;
      font-size: rem(16);
      font-weight: var(--fw-regular);
      svg {
        margin-right: rem(10);
      }
    }
    .icon {
      background-color: #fff8;
      padding: rem(8);
    }
    //
    .list-share {
      position: relative;
      &-btn {
        display: none;
        @include screen(lg) {
          display: block;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
      &-items {
        @include screen(lg) {
          position: absolute;
          bottom: calc(-100% - rem(64));
          right: 0;
          &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            right: 7%;
            margin-left: -5px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent transparent var(--colors-white)
              transparent;
          }
          visibility: hidden;
          opacity: 0;
          transition: 0.3s;
          &.open {
            visibility: visible;
            bottom: calc(-100% - rem(44));
            opacity: 1;
          }
        }
      }
      &-items-wrapper {
        display: flex;
        gap: rem(8);
        @include screen(lg) {
          flex-direction: column;
          gap: 0;
          min-width: rem(160);
          background-color: var(--colors-white);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.185);
          border-radius: rem(10);
          overflow: hidden;
        }
      }
      &-item {
        @include screen(lg) {
          display: flex;
          column-gap: rem(6);
          align-items: center;
          background-color: var(--colors-white);
          transition: 0.3s;
          &:hover {
            background-color: #f8f8f8;
          }
        }
        .share,
        .qrcode {
          @include screen(lg) {
            width: 100%;
          }
        }
        .icon {
          svg {
            width: rem(22);
            @include screen(lg) {
              width: rem(18);
              margin-right: rem(12);
            }
          }
          @include screen(lg) {
            width: 100% !important;
            font-size: rem(16);
            font-weight: var(--fw-regular);
            border: none;
            border-radius: 0;
            padding: rem(20) rem(16);
            justify-content: flex-start;
          }
        }
        .btn--secondary {
          @include screen(lg) {
            &:hover {
              background-color: unset !important;
            }
          }
        }
      }
      &-label {
        display: none;
        @include screen(lg) {
          display: inline-block;
        }
      }
    }
  }
  // Note
  &__note {
    max-width: rem(420);
    margin: 0 auto;
    text-align: center;
    margin-top: rem(24);
    @include screen(md) {
      max-width: rem(540);
      padding-inline: rem(24);
    }
    @include screen(lg) {
      max-width: rem(768);
      margin: rem(24) 0 0 0;
      padding-inline: 0;
    }
  }
  // Details
  &__contacts {
    padding-bottom: rem(40);
    margin-top: rem(32);
    a:not(a[role="button"]):hover {
      text-decoration: underline;
    }
    @include screen(md) {
      padding-bottom: rem(56);
      padding-inline: rem(24);
    }
    @include screen(lg) {
      padding-inline: rem(56);
    }
    &-item {
      padding-block: rem(16);
      display: flex;
      align-items: center;
      column-gap: rem(16);
      border-bottom: 1px solid var(--colors-gray-light);
      &.adr {
        align-items: flex-start;
      }
      @include screen(md) {
        font-size: rem(18);
        padding-block: rem(20);
      }
      @include screen(lg) {
        padding-block: rem(24);
      }
      &:last-of-type {
        border: none;
      }
      &:last-child {
        width: 100%;
        min-height: rem(24);
        word-wrap: break-word;
      }
      &-icon {
        width: rem(24);
        height: rem(24);
        line-height: 0;
        path {
          fill: var(--colors-dark) !important;
        }
        @include screen(md) {
          width: rem(32);
          height: rem(32);
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
      &-link {
        margin-block: auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 80%;
        min-height: rem(24);
        & + span {
          margin-left: rem(-10) !important;
        }
      }
      &-text {
        width: 80%;
      }
      &-label {
        display: block;
        font-weight: var(--fw-semiBold);
        text-transform: capitalize;
      }
      &-private {
        color: var(--colors-gray);
        font-size: rem(14) !important;
        text-transform: capitalize;
        margin-left: rem(6);
        text-decoration: none !important;
        &:hover {
          text-decoration: none !important;
        }
      }
    }
    // Theme icons
    &-tm2 {
      max-width: rem(280);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: rem(24);
      margin-top: rem(32);
      margin-left: auto;
      margin-right: auto;
      @include screen(sm) {
        max-width: rem(380);
      }
      @include screen(lg) {
        max-width: unset;
        margin-top: rem(40);
        margin-left: rem(56);
        margin-right: rem(56);
      }
      &-item {
        &-link {
          display: flex;
          flex-direction: column;
          align-items: center;
          // filter: brightness(97%);
          @include screen(lg) {
            &:hover > span {
              filter: brightness(95%);
            }
          }
        }
        &-icon {
          display: flex;
          width: rem(72);
          height: rem(72);
          margin-bottom: rem(4);
          transition: 0.3s;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        &-name {
          font-size: rem(14);
          text-transform: capitalize;
          max-width: rem(72);
          white-space: nowrap;
          word-wrap: normal;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  // Profile without cover
  &.no-cover {
    .profile-img {
      height: rem(96);
      @include screen(lg) {
        height: rem(56);
      }
      @include screen(lg) {
        margin-bottom: 0;
      }
      .img {
        background-color: var(--colors-white);
      }
      .avatar {
        @include screen(lg) {
          top: 90%;
          left: 0;
          transform: translate(0, -50%);
        }
        &-wrapper {
          box-shadow: none;
        }
      }
    }
    @include screen(lg) {
      .card-profile {
        &__heading {
          align-items: flex-start !important;
          justify-content: flex-start;
          text-align: left;
          padding-left: rem(208);
        }
        &__note {
          max-width: unset;
          text-align: left;
        }
        &__contacts {
          padding-left: rem(208);
          &-tm2 {
            justify-content: flex-start;
            margin-left: rem(208);
          }
        }
      }
      .see-more-btn {
        margin-left: 0;
      }
    }
  }
}

:root {
  // --custom-font: "inter", sans-serif;
}

.custom-font {
  font-family: var(--custom-font);
  * {
    font-family: var(--custom-font);
  }
}
