@use "../abstracts" as *;

.err {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  padding: rem(24);
  h1 {
    font-size: rem(24);
  }
  p {
    max-width: rem(445);
    font-size: rem(14);
    margin-block: rem(16) rem(20);
  }
  button,
  [role="button"] {
    width: fit-content;
  }
}
