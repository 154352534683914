@use "../abstracts" as *;

.settings {
  .container {
    padding-block: rem(24);
    @include screen(lg) {
      padding-block: rem(40);
    }
  }
  h2 {
    font-size: rem(18);
    font-weight: var(--fw-bold);
  }
  &__paragraph {
    max-width: rem(445);
  }
  &__account,
  &__change-password {
    margin-top: rem(20);
  }
  &__form {
    width: 100%;
    max-width: rem(445);
    margin-top: rem(16);
    display: flex;
    flex-direction: column;
    gap: rem(16);
    input {
      height: rem(48) !important;
    }
  }
  [type="submit"] {
    margin-top: rem(14);
    margin-left: auto;
    @include screen(md) {
      width: rem(200) !important;
    }
  }
}

.password-changed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.152);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  &__box {
    width: 100%;
    max-width: rem(380);
    padding: 24px;
    background-color: var(--colors-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: rem(5);
    gap: rem(16);
    p {
      margin-top: rem(6);
    }
  }
}
