@use "../abstracts" as *;

.reset-password {
  .container {
    min-height: calc(100vh - rem(80));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding-top: rem(70);
    @include screen(lg) {
      padding-top: rem(48);
      justify-content: center;
    }
  }
  &__title {
    font-size: rem(28);
    line-height: line-hg(29, 24);
    margin-top: rem(14);
  }
  &__paragraph {
    max-width: rem(380);
    padding-block: rem(10) rem(24);
  }
  &__form {
    width: 100%;
    max-width: rem(445);
    display: flex;
    flex-direction: column;
    gap: rem(16);
    input {
      height: rem(48) !important;
    }
  }
  [type="submit"] {
    margin-top: rem(14);
  }
  &__back {
    margin: 0 auto;
    display: flex;
    align-items: center;
    span {
      margin-left: rem(6);
    }
  }
}
