@use "../abstracts" as *;

.switch {
  position: relative;
  display: inline-block;
  width: rem(48);
  flex-shrink: 0;
  height: rem(28);
  margin: 0 !important;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--colors-gray-light);
  transition: 0.4s;
  border-radius: rem(28);
}

.slider:before {
  position: absolute;
  content: "";
  height: rem(20);
  width: rem(20);
  left: rem(4);
  bottom: rem(4);
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--colors-green);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--colors-green);
}

input:checked + .slider:before {
  transform: translateX(rem(20));
}
