@use "../abstracts" as *;

html {
  // scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--colors-red) #ccc;
}
::-webkit-scrollbar {
  max-width: 0.375rem;
  &-track {
    background-color: transparent;
  }
  &-thumb {
    background-color: var(--colors-red);
    &:hover {
      background-color: #ee4545c4;
    }
  }
}
::selection {
  color: var(--colors-dark);
  background-color: rgb(255, 230, 230);
}

body,
p {
  color: var(--colors-dark);
}
main {
  min-height: calc(100vh - rem(125));
  @include screen(md) {
    min-height: calc(100vh - rem(145));
  }
}
.profile-img {
  width: 100%;
  height: rem(176);
  position: relative;
  margin-bottom: rem(80);
  @include screen(md) {
    height: rem(280);
  }
  @include screen(lg) {
    height: rem(256);
    margin-bottom: rem(24);
  }
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.img {
  width: inherit;
  height: inherit;
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.avatar {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 60%);
  @include screen(lg) {
    left: rem(40);
    transform: translate(0, 64%);
  }
  &-wrapper {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.047);
    img {
      color: rgba(0, 0, 0, 0);
    }
    line-height: 0;
    background-color: var(--colors-white);
    border: 4px solid var(--colors-white);
    @include circle(rem(140));
    overflow: hidden;
    position: relative;
    @include screen(md) {
      @include circle(rem(160));
    }
    img,
    svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
header .avatar {
  &-wrapper {
    box-shadow: none;
  }
}
img {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}
