@use "../abstracts" as *;

.error-msg-log {
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: rem(6);
  background-color: var(--colors-red);
  color: var(--colors-white);
  font-size: rem(12);
  min-width: rem(275);
  max-width: rem(375);
  position: fixed;
  top: rem(40);
  left: 50%;
  z-index: 9999;
  transform: translateX(-50%);
  padding: rem(12) rem(16);
  border-radius: rem(5);
  box-shadow: 0 6px 16px hsl(0deg 0% 0% / 0.3);
  @include screen(md) {
    font-size: rem(14);
  }
  .content {
    display: flex;
    align-items: center;
    gap: rem(6);
  }
  button {
    width: rem(20);
    height: rem(20);
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: var(--colors-white);
      }
    }
  }
}
