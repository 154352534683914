@use "../abstracts" as *;
/* Phone Mockup */
.home {
  position: relative;
  min-height: inherit;
  &[data-bg="true"]::before {
    content: "";
    width: rem(376);
    height: rem(253);
    background: url(../../assets/circles-pattern.svg) no-repeat;
    background-size: cover;
    position: absolute;
    top: rem(-90);
    right: 50%;
    z-index: -100;
    @include screen(sm) {
      width: rem(732);
      height: rem(494);
      top: rem(-150);
    }
    @include screen(lg) {
      top: rem(-113);
      right: unset;
      left: rem(-390);
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: inherit;
    padding-top: rem(48);
    padding-bottom: rem(16);
    @include screen(lg) {
      flex-direction: row;
      justify-content: space-evenly;
    }
    @include screen(xl) {
      width: rem(1036);
      padding-right: rem(32);
      padding-block: rem(48);
    }
  }
  &__phone-mockup {
    width: rem(166);
    height: rem(310);
    position: relative;
    margin-bottom: rem(32);
    @include screen(sm) {
      width: rem(217);
      height: rem(408);
    }
    @include screen(lg) {
      width: rem(310);
      height: rem(560);
      margin-left: rem(40);
      margin-bottom: rem(0);
    }
    @include screen(xx) {
      width: rem(391);
      height: rem(730);
      margin-left: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 6%;
      transform: scale(1.2);
    }
    &-intro {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--colors-white);
      width: 70%;
      height: 84%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: rem(40);
        height: rem(40);
        @include screen(lg) {
          width: rem(80);
          height: rem(80);
        }
        @include screen(xx) {
          width: rem(100);
          height: rem(100);
        }
      }
    }
  }
  &__signs {
    width: 100%;
    max-width: rem(445);
    @include screen(xx) {
      margin-left: rem(64);
    }
    input {
      height: rem(48);
    }
  }
  &__sign {
    h1 {
      max-width: rem(400);
      text-transform: capitalize;
      @include screen(sm) {
        max-width: 100%;
      }
    }
    p {
      padding-block: rem(16) rem(24);
      @include screen(sm) {
        padding-block: rem(24) rem(32);
      }
    }
    &-buttons {
      display: flex;
      flex-direction: column;
      row-gap: rem(20);
    }
  }
  &__sign-up,
  &__login,
  &__forget-password {
    &-heading {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(16);
      & > a {
        line-height: 0;
        display: flex;
        align-items: center;
        gap: rem(6);
        svg {
          width: rem(24);
          height: rem(24);
        }
      }
      h1 {
        font-size: rem(24);
        line-height: line-hg(29, 24);
      }
    }
    p {
      text-align: left;
      padding-block: rem(8) rem(24);
    }
    &-form {
      display: flex;
      flex-direction: column;
      row-gap: rem(18);
      button {
        margin-top: rem(16);
      }
    }
  }
  &__login {
    &-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-remember {
      display: flex;
      align-items: center;
      .checkbox {
        width: rem(24) !important;
        height: rem(24) !important;
        margin-right: rem(6);
        input[type="checkbox"] {
          width: rem(24) !important;
          height: rem(24) !important;
          border-color: var(--colors-dark) !important;
          &:checked {
            border-color: var(--colors-red) !important;
            background-color: var(--colors-red);
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: url(../../assets/check-icon.svg);
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          @include screen(lg) {
            &:hover {
              box-shadow: 0 0 3px 3px #3a3a3a15;
            }
            &:checked {
              &:hover {
                box-shadow: 0 0 3px 3px #ee454526;
              }
            }
          }
        }
      }
    }
    [type="submit"] {
      margin-top: 0;
    }
  }
  &__forget-password {
    [type="submit"] {
      margin-top: rem(10);
    }
  }
  // Social media
  .social-media {
    display: flex;
    justify-content: center;
    gap: rem(4);
    margin-top: rem(48);
    @include screen(lg) {
      position: fixed;
      top: 50%;
      right: rem(24);
      flex-direction: column;
      gap: rem(10);
      margin-top: 0;
      transform: translateY(-50%);
    }
    li {
      transition: 0.3s;
      a {
        @include circle(rem(40));
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
      }
      svg {
        width: rem(26);
        height: rem(26);
        @include screen(lg) {
          width: rem(28);
          height: rem(28);
        }
        path {
          fill: var(--colors-dark);
          transition: 0.3s;
        }
      }
      @include screen(lg) {
        &:hover {
          transform: scale(1.2);
          .facebook {
            path {
              fill: #4267b2;
            }
          }
          .instagram {
            path {
              fill: #e1306c;
            }
          }
          .whatsapp {
            path {
              fill: #25d366;
            }
          }
          .twitter {
            path {
              fill: #1da1f2;
            }
          }
          .tiktok {
            path {
              fill: #1b1a1a;
            }
          }
          .website {
            path {
              fill: var(--colors-red);
            }
          }
        }
      }
    }
  }
}
