@use "../abstracts" as *;

.btn {
  width: 100%;
  height: 48px;
  padding: rem(12) rem(20);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(9999);
  &.icon {
    width: rem(40);
    height: rem(40);
    padding: 0;
  }
  &--primary {
    background-color: var(--colors-red);
    color: var(--colors-white);
    transition: 0.5s !important;
    &.icon {
      path {
        fill: var(--colors-white) !important;
      }
    }
    &:hover {
      background-color: #cf2732;
    }
  }
  &--secondary {
    color: var(--colors-dark);
    border: 1px solid var(--colors-gray-light);
    &:hover {
      background-color: #f8f8f8;
    }
    &.icon {
      path {
        fill: var(--colors-dark) !important;
      }
    }
  }
  &--gray {
    background-color: var(--colors-gray);
    color: var(--colors-white);
    &:hover {
      background-color: #717171;
    }
    &.icon {
      path {
        fill: var(--colors-white) !important;
      }
    }
  }
  &--tertiary {
    font-size: rem(16);
    font-weight: var(--fw-regular);
    justify-content: left;
    color: var(--colors-gray);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='3' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  &--blue {
    background-color: var(--colors-blue);
    &:hover {
      background-color: #0f93bb;
    }
    &.icon {
      path {
        fill: var(--colors-white) !important;
      }
    }
  }
  &--green {
    color: var(--colors-white);
    background-color: var(--colors-green);
    &:hover {
      background-color: #1cb587;
    }
    &.icon {
      path {
        fill: var(--colors-white) !important;
      }
    }
  }
  &--delete {
    width: rem(32) !important;
    height: rem(32) !important;
    border-radius: 50% !important;
    svg {
      path {
        transition: fill 0.3s;
      }
    }
    @include screen(lg) {
      &:hover {
        background-color: #fbf0f0;
        svg {
          path {
            fill: var(--colors-red) !important;
          }
        }
      }
    }
  }
  &[disabled] {
    opacity: 0.8;
  }
}

button:not(.btn--tertiary),
[role="button"] {
  &:active {
    transform: scale(0.95);
  }
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}
