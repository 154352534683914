@use "../abstracts" as *;

.uploader {
  font-size: 1rem;
  font-weight: var(--fw-regular);
  border: 1px solid var(--colors-gray-light);
  border-radius: 0.3125rem;
  padding: 30px 0;
  margin-top: 50px;
  border: none;
  border-top: 1px solid var(--colors-gray-light);
  border-radius: 0;
  padding-top: 30px;
  @include screen(sm) {
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: start;
    flex-wrap: wrap;
    button,
    a {
      font-weight: var(--fw-regular);
    }
  }
  .row {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
    flex-wrap: wrap;
    .input {
      flex: 1;
      min-width: 300px;
    }
  }
  input {
    padding: 8px;
  }
  h4 {
    margin-bottom: 24px;
  }
}

.catalog-view {
  font-size: 1rem;
  // border: 1px solid var(--colors-gray-light);
  // border-radius: 0.3125rem;
  // padding: 30px 0;
  // border: none;
  // border-top: 1px solid var(--colors-gray-light);
  // border-radius: 0;
  // padding-top: 30px;1
  @include screen(sm) {
  }
  .buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
    button,
    a {
      font-weight: var(--fw-regular);
    }
  }
  h4 {
    // margin-bottom: 24px;
  }
}
