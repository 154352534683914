:root {
  --colors-dark: #3a3a3a;
  --colors-red: #e12a36;
  --colors-white: #ffffff;
  --colors-blue: #12A9D9;
  --colors-green: #20C997;
  --colors-gray: #808080;
  --colors-gray: gray;
  --colors-gray-light: #C0C0C0;
}
