@use "../abstracts" as *;

.create-card {
  .container {
    padding-block: rem(40);
  }
  &__heading {
    text-align: center;
    padding-bottom: rem(40);
    @include screen(md) {
      text-align: left;
    }
    h1 {
      font-size: rem(36);
      line-height: line-hg(48, 40);
      @include screen(md) {
        font-size: rem(56);
        line-height: line-hg(68, 56);
      }
       @include screen(md) {
        font-size: rem(64);
        line-height: line-hg(77, 64);
      }
    }
    p {
      max-width: rem(425);
      padding-top: rem(16);
      margin-inline: auto;
      @include screen(md) {
        margin: 0;
      }
    }
  }
}
