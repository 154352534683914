@use "../abstracts" as *;
// Google Font - Inter
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

// typography
body {
  font-family: var(--fm);
  font-family: 'inter', sans-serif;
}
h1 {
  font-size: rem(32);
  line-height: line-hg(38, 32);
  @include screen(sm) {
    font-size: rem(48);
    line-height: line-hg(58, 48);
  }
}
body,
p {
  font-size: rem(16);
  line-height: line-hg(26, 16);
}
button,
[type="button"],
[role="button"] {
  font-size: rem(16);
  font-weight: var(--fw-bold);
  @include screen(md) {
    font-size: rem(18);
  }
}
small {
  font-size: rem(12);
  font-weight: var(--fw-medium);
  @include screen(lg) {
    font-size: rem(14);
  }
}
label,
::placeholder {
  text-transform: capitalize;
  font-size: rem(16);
  line-height: line-hg(20, 16);
  font-weight: var(--fw-medium);
}
