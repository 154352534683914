@use "../abstracts" as *;

.password-set {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--colors-white);
  padding: rem(24);
  &__content {
      min-height: calc(100vh - rem(80));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__icon {
    @include circle(rem(60));
    border: 1px solid var(--colors-gray-light);
    background-color: var(--colors-green);
    svg {
      width: rem(60);
      height: rem(60);
      path {
        fill: var(--colors-white);
      }
    }
  }
  h1 {
    font-size: rem(28);
    margin-top: rem(16);
  }
  p {
    max-width: rem(360);
    margin: rem(6) 0 rem(20);
  }
  [role="button"] {
    width: fit-content;
    font-size: rem(12);
    display: flex;
    align-items: center;
    svg {
      path {
        fill: var(--colors-white);
      }
    }
    span {
      margin-left: rem(6);
    }
  }
  &__footer {
    small {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-logo {
      width: rem(45);
      height: rem(11);
      margin-left: rem(6);
      line-height: 0;
      @include screen(lg) {
        width: rem(80);
        height: rem(17);
      }
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
