@use "../abstracts" as *;

.not-found {
  .container {
    padding-top: rem(40);
    @include screen(md) {
      padding-top: rem(80);
    }
  }
}
