@use "../abstracts" as *;

.header {
  background-color: var(--colors-white);
  & > .container {
    height: rem(65);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    font-size: rem(20);
    font-weight: var(--fw-bold);
    color: var(--colors-dark);
    display: flex;
    align-items: center;
    @include screen(xl) {
      font-size: rem(22);
    }
    a {
      line-height: 0;
      // width: rem(32);
      height: rem(28);
      display: flex;
      column-gap: rem(4);
      align-items: center;
      svg {
        // width: 100%;
        height: 100%;
        path {
          // fill: var(--colors-dark);
        }
      }
    }
  }
  &__menu-list {
    display: none;
    position: fixed;
    top: rem(64);
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    background-color: var(--colors-white);
    @include screen(lg) {
      display: block !important;
      position: unset;
      width: auto;
      height: auto;
    }
    &.open {
      display: block;
    }
    .container {
      padding-top: rem(16);
      @include screen(md) {
        padding-top: rem(20);
      }
      @include screen(lg) {
        padding: 0;
        width: auto;
        margin: 0;
      }
    }
  }

  &__user {
    &-info {
      display: flex;
      align-items: center;
      column-gap: rem(16);
      @include screen(lg) {
        display: none;
      }
    }
    &-avatar {
      .avatar-wrapper {
        width: rem(60);
        height: rem(60);
      }
    }
    &-name,
    &-email {
      @include ellipsis(rem(200));
    }
    &-name {
      font-size: rem(18);
      font-weight: var(--fw-bold);
      color: var(--colors-dark);
    }
    &-email {
      font-size: rem(12);
    }
  }
  &__registered {
    &-hd {
      font-size: rem(18);
      font-weight: var(--fw-bold);
    }
  }
  nav {
    width: fit-content;
  }
  &__nav {
    &-list {
      margin-top: rem(20);
      @include screen(lg) {
        display: flex;
        align-items: center;
        gap: 0;
        margin-top: 0;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: rem(10);
      @include screen(lg) {
        margin-bottom: 0;
      }
      & > a {
        letter-spacing: rem(0.5);
        @include screen(lg) {
          border-bottom: 2px solid transparent;
          &:hover {
            background-color: #f8f8f8;
          }
        }
        &.active {
          border-color: var(--colors-red);
        }
      }
      & > * {
        min-width: rem(88);
        min-height: rem(40);
        transition: 0.2s !important;
        border-radius: rem(5) rem(5) 0 0;
        display: flex;
        align-items: center;
        font-size: rem(16);
        line-height: line-hg(24, 16);
        font-weight: var(--fw-medium) !important;
        text-transform: capitalize;
        @include screen(md) {
          font-weight: var(--fw-semiBold) !important;
        }
        @include screen(lg) {
          justify-content: center;
          padding-inline: rem(8);
        }
        svg {
          margin-right: rem(10);
        }
      }
      &:not(:last-of-type) svg {
        @include screen(lg) {
          display: none;
        }
      }
      &-nfc {
        svg {
          path {
            fill: var(--colors-blue) !important;
          }
        }
        @include screen(lg) {
          display: none !important;
        }
      }
      &-logout {
        color: var(--colors-red);
        path {
          fill: var(--colors-red);
        }
        @include screen(lg) {
          margin-left: rem(10);
          &:hover {
            background-color: #fcf3f3;
          }
        }
      }
    }
  }

  &__sign {
    max-width: rem(445);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(40);
    @include screen(lg) {
      padding-top: 0;
    }
    &-heading {
      max-width: rem(360);
      text-align: center;
      h2 {
        font-size: rem(28);
        margin-bottom: rem(6);
        @include screen(md) {
          font-size: rem(32);
        }
      }
      @include screen(lg) {
        display: none;
      }
    }
    &-btn {
      display: flex;
      flex-direction: column;
      gap: rem(16);
      width: 100%;
      margin-top: rem(32);
      @include screen(lg) {
        flex-direction: row;
        margin-top: 0;
        a {
          width: rem(165);
          height: rem(40);
          font-size: rem(16);
        }
      }
    }
  }
  &__toggle-btn {
    width: rem(32);
    height: rem(32);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 7777;
    @include screen(lg) {
      display: none;
    }
  }
}
