@use "../abstracts" as *;

.box {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  @include screen(lg) {
    margin: 0;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.see-more-btn {
  font-size: rem(16);
  min-width: rem(125);
  height: rem(40);
  padding: rem(6) rem(10);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: rem(25);
  overflow: hidden;
  @include screen(lg) {
    margin: rem(10) auto;
    border: 1px solid var(--colors-gray-light);
    &:hover {
      background-color: #f8f8f8;
    }
  }
}
