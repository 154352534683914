.intro-mob {
  opacity: 0;
  animation: intro-mob 3s 1s forwards;
}
@keyframes intro-mob {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.fade-out-intro {
  animation: fade-out-intro 0.7s 4s forwards;
}
@keyframes fade-out-intro {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fade-in-intro {
  opacity: 0;
  animation: fade-in-intro 1s 0.5s forwards;
}
@keyframes fade-in-intro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 0.7s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-nfc {
  animation: fade-in-nfc 0.3s  forwards;
}
@keyframes fade-in-nfc {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-up-nfc {
  animation: slide-up-nfc 0.5s ;
}
@keyframes slide-up-nfc {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0);
  }
}
