@use "../abstracts" as *;

.card-form {
  &__group {
    &--main {
      display: flex;
      flex-direction: column;
      margin-bottom: rem(56);
      @include screen(md) {
        margin-bottom: rem(80);
        gap: rem(40);
      }
      @include screen(lg) {
        align-items: center;
        margin-bottom: rem(80);
      }
    }
    &--body {
      display: flex;
      flex-direction: column;
      row-gap: rem(24);
      &-fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        &-card {
          display: flex;
          flex-direction: column;
          row-gap: rem(16);
          @include screen(md) {
            flex-direction: row;
            column-gap: rem(10);
            .field {
              width: calc(70% - 0.09375rem) !important;
              &:last-of-type {
                width: calc(30% - 0.09375rem) !important;
              }
            }
          }
        }
      }
    }
  }
  .profile-img {
    margin-bottom: 0 !important;
  }
  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(24);
    position: relative;
    @include screen(md) {
      flex-direction: row;
      align-items: flex-start;
      .avatar-wrapper {
        box-shadow: none;
      }
    }
    @include screen(lg) {
      gap: rem(16);
    }
    &-btn {
      display: flex;
      gap: rem(16);
      @include screen(md) {
        margin-top: rem(24);
        flex-direction: column;
      }
    }
  }
  &__main-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(16);
    margin-top: rem(-64);
    @include screen(md) {
      margin-top: rem(0);
      flex-direction: row;
      gap: rem(24);
    }
  }
  button:not([type="submit"]) {
    border-radius: rem(5);
  }
  button[type="submit"] {
    width: fit-content !important;
    min-width: rem(140);
    @include screen(lg) {
      margin-left: rem(240);
    }
  }
}
.field {
  display: flex;
  flex-direction: column;
  text-align: left;
  & > div {
    position: relative;
  }
  .show-password {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: rem(48);
    height: rem(48);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
label,
legend {
  font-size: rem(16);
  font-weight: var(--fw-semiBold);
  margin-bottom: rem(6);
}
input:not([type="checkbox"]),
select,
textarea {
  width: 100%;
  font-size: rem(16);
  font-weight: var(--fw-regular);
  height: rem(40);
  padding-inline: rem(16);
  background-color: #f8f8f8;
  border: 1px solid var(--colors-gray-light);
  border-radius: rem(5);
  outline: none;
  &::placeholder {
    color: var(--colors-gray);
    font-weight: var(--fw-regular);
  }
  &:placeholder-shown {
    background-color: var(--colors-white);
    border-color: var(--colors-gray-light);
  }
}
input[name$="word"] {
  padding-right: rem(56);
}
textarea {
  height: auto;
  padding: rem(16);
}
.select {
  position: relative;
  z-index: 1;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-transform: capitalize;
    background-color: unset;
    &::-ms-expand {
      display: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: rem(40);
    background: var(--colors-white) url(../../assets/arrow-select-icon.svg)
      no-repeat calc(100% - rem(16)) 50%;
    border-radius: rem(5);
  }
}
.checkbox {
  &,
  input {
    width: rem(40) !important;
    height: rem(40) !important;
  }
  input {
    -webkit-appearance: none;
    appearance: none;
    border-radius: rem(5);
    outline: none;
    cursor: pointer;
    border: 1px solid var(--colors-gray);
    &:checked {
      border-color: var(--colors-green) !important;
      background-color: var(--colors-green);
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/check-icon.svg);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
.select-cover-button {
  position: absolute;
  top: rem(16);
  right: rem(16);
  z-index: 10;
  display: flex;
  gap: rem(16);
  button {
    @include circle(rem(40));
  }
}
fieldset.contact-name {
  width: 100%;
  .fieldset-container {
    width: 100%;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: rem(10);
    div {
      display: flex;
      gap: rem(10);
      &:first-of-type {
        input {
          width: calc(50% - rem(1.5));
        }
      }
      &:last-of-type {
        flex-direction: column;
        @include screen(md) {
          flex-direction: row;
          input {
            width: calc(50% - rem(1.5));
          }
        }
      }
    }
  }
}

fieldset {
  @include screen(md) {
    &.birthday,
    &.occupation,
    &.note {
      padding-right: rem(46);
    }
    &.field > button,
    .adr-group {
      width: calc(100% - rem(108));
    }
  }
}
fieldset.birthday {
  .fieldset-container {
    @include screen(lg) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  legend {
    @include screen(lg) {
      min-width: rem(240);
      padding-left: rem(24);
    }
  }
  .inputs {
    width: 100%;
    display: flex;
    column-gap: rem(10);
    input {
      width: 100%;
    }
  }
}
fieldset.occupation {
  .fieldset-container {
    @include screen(lg) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  legend {
    @include screen(lg) {
      min-width: rem(240);
      padding-left: rem(24);
    }
  }
  .inputs {
    width: 100%;
    display: flex;
    gap: rem(10);
    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: rem(10);
      @include screen(md) {
        flex-direction: row;
      }
    }
  }
}
fieldset.note {
  .fieldset-container {
    width: 100%;
    @include screen(lg) {
      display: flex;
    }
  }
  legend {
    @include screen(lg) {
      min-width: rem(240);
      padding-left: rem(24);
    }
  }
  .inputs {
    width: 100%;
    display: flex;
    gap: rem(10);
  }
}

fieldset.field {
  &,
  .fieldset-container {
    width: 100%;
    @include screen(lg) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  legend {
    @include screen(lg) {
      min-width: rem(240);
      padding-left: rem(24);
    }
  }
  .inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(10);
    @include screen(md) {
      .btn--tertiary.btn {
        width: calc(100% - rem(108));
      }
    }
  }
  .list,
  .item,
  .adr-group,
  .adr-group div {
    display: flex;
    flex-wrap: wrap;
    gap: rem(10);
  }
  .item {
    width: 100%;
    .select {
      order: 1;
      width: calc(100% - rem(108));
      @include screen(md) {
        width: auto;
        min-width: rem(176);
      }
    }
    .control {
      order: 2;
      display: flex;
      align-items: center;
      column-gap: rem(10);
    }
    & > input {
      order: 3;
      display: flex;
      @include screen(md) {
        order: 2;
        width: calc(100% - rem(290));
      }
    }
    .adr-group {
      order: 4;
      div {
        @include screen(md) {
          display: flex;
          flex-wrap: nowrap;
          input:last-of-type {
            width: 65%;
          }
        }
      }
    }
  }
}

.field,
fieldset.birthday {
  position: relative;
  &.error {
    color: var(--colors-red);
    input {
      color: var(--colors-red);
      border-color: var(--colors-red);
      &::placeholder {
        color: var(--colors-red);
      }
    }
    .error-msg {
      position: absolute;
      bottom: rem(-20);
      right: 0;
      font-size: rem(12);
    }
    svg {
      path {
        fill: var(--colors-red) !important;
      }
    }
  }
}
fieldset.birthday {
  .inputs {
    position: relative;
  }
  &.error {
    .error-msg {
      left: 0;
    }
  }
}

fieldset.contact-name {
  .inputs {
    position: relative;
  }
  &.error {
    color: var(--colors-red);
    input[name$="firstName"] {
      color: var(--colors-red);
      border-color: var(--colors-red);
      &::placeholder {
        color: var(--colors-red);
      }
    }
    .error-msg {
      position: absolute;
      bottom: rem(-20);
      left: 0;
      font-size: rem(12);
    }
    svg {
      path {
        fill: var(--colors-red) !important;
      }
    }
  }
}

form .errors {
  width: 100%;
  font-size: rem(14);
  background-color: #f8d7da;
  color: var(--colors-red);
  font-weight: var(--fw-semiBold);
  border-radius: rem(5);
  padding: rem(16);
  margin-top: rem(16);
  @include screen(md) {
    width: calc(100% - rem(108));
  }
  @include screen(lg) {
    width: calc(100% - rem(392));
    margin-left: rem(240);
  }
  li::before {
    content: "-";
    margin-right: rem(6);
  }
}

input[type="checkbox"] {
  @include screen(lg) {
    transition: box-shadow 0.3s;
    &:hover {
      box-shadow: 0 0 3px 3px #20c99638;
    }
  }
}
